import { createSlice, createAsyncThunk, createListenerMiddleware } from '@reduxjs/toolkit';
import { TypedListener } from "@/store";
import { apiClient } from '@/utils';
import { LOCATION_CHANGE, LocationChangeAction, push } from 'connected-react-router';
import qs from 'qs';
import Notifications from 'react-notification-system-redux';
import * as actions from '@/store/actions';

export const STRIPE_AUTH_PATHNAME = '/stripe-auth';

interface AuthData {
    code: string;
    state: string;
    error: string;
    scope: string;
}

interface Entity {
    redirectUrl?: string;
    authData?: Partial<AuthData>;
    test?: boolean;
}

interface InitialState {
    entity: Entity | null;
    isLoading: boolean;
    error: any;
}

export const initialState: InitialState = {
    entity: null,
    error: null,
    isLoading: false,
};

// Middlewares
export const stripeAuthListener = createListenerMiddleware();
export const startStripeAuthListener = stripeAuthListener.startListening as TypedListener;


// Thunks
export const init = createAsyncThunk(
    '/stripe/authenticate/connect',
    async (_: undefined, thunkApi) => {
        try {
            const response = await apiClient.get('/payment/stripe/v1/account/connect', {
                params: {
                    RedirectUri: `${window.location.origin}/#${STRIPE_AUTH_PATHNAME}`
                },
            });
            return thunkApi.fulfillWithValue(response.data.Url as string)
        } catch (error: any) {
            return thunkApi.rejectWithValue(error);
        }
        
    }
)

export const authenticate = createAsyncThunk(
    '/stripe/authenticate/authenticate',
    async (authData: Partial<AuthData>, thunkApi) => {
        try {
            const response = await apiClient.post('/payment-settings/stripe-api/stripe-auth', authData);
            return thunkApi.fulfillWithValue(authData)
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
)

// Slice

const stripeAuthSlice = createSlice({
    name: 'stripeAuth',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(init.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(init.fulfilled, (state, action) => {
            state.entity = {
                redirectUrl: action.payload
            };
            state.isLoading = false;
            state.error = false;
        });

        builder.addCase(init.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(authenticate.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(authenticate.fulfilled, (state, action) => {
            state.entity = {
                redirectUrl: state.entity?.redirectUrl,
                authData: action.payload
            };

            state.isLoading = false;
            state.error = false;
        });

        builder.addCase(authenticate.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        })

        builder.addCase(actions.FETCH_STRIPE_API_SETTINGS.SUCCESS, (state) => {
            state.entity = {
                ...(state.entity ? state.entity : {}),
                test: true
            }
        })

    }
});

startStripeAuthListener({
    predicate: (action ) => action.type === LOCATION_CHANGE ,
    effect: async (action, api) => {
        const searchParams = qs.parse(action.payload.location.search);
        const authData: Partial<AuthData> = {
            code: searchParams.code as string,
            scope: searchParams.scope as string,
            state: searchParams.state as string,
        }


        if (action.payload.location.pathname === STRIPE_AUTH_PATHNAME && searchParams.code) {
            await api.condition((action, state) => {
                if(state.authenticate.data) {
                    return state.authenticate.isLoggedIn    
                }
                return false
            }).then(() => {
                api.dispatch(authenticate(authData));
            })
        }
    }
});

startStripeAuthListener({
    matcher: authenticate.fulfilled.match,
    effect: async (action, api) => {
        const location = api.getState().router.location;

        api.dispatch(push({
            pathname: `/payment-settings`,
            hash: 'stripe-api',
            search: location.search
        }))

    }
})

startStripeAuthListener({
    matcher: authenticate.rejected.match,
    effect: async (action: any, api) => {
        const location = api.getState().router.location;        

        api.dispatch(push({
            pathname: `/payment-settings`,
            hash: 'stripe-api',
            search: location.search
        }))

        api.dispatch(Notifications.error({ message: action.payload.response.data.Message}));
    }
})

export default stripeAuthSlice;
