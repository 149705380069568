import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createHashHistory } from 'history';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { configureStore, TypedStartListening } from '@reduxjs/toolkit';
import bmApiService from '@/services/bmApi';

import createRootReducer from '@/store/reducers';
import sagas from '@/store/sagas';
import { isDate } from 'lodash';
import { authListener } from './store/reducers/newAuth';
import { formsListener } from './store/reducers/cancelLicenseQuestionaire';
import { resyncCodelockListener } from './store/reducers/resyncCodelock';
import { eaccountingListener } from './store/reducers/eaccounting';
import { correlationIdListener } from './store/reducers/correlationId';
import { rtkQueryErrorLogger } from './store/rtkLicenseMiddleware';
import { calculatePriceListener } from './store/reducers/calculatePrice'
import { stripeAuthListener } from './store/reducers/stripeAuthSlice'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
    }
}

export const history = createHashHistory();

export const createStoreWithInitialState = (initialState?: any) => {
    const rootReducer = createRootReducer(history);
    const reduxRouterMiddleware = routerMiddleware(history);
    const sagaMiddleware = createSagaMiddleware();

    const store = configureStore({
        preloadedState: initialState,
        reducer: rootReducer,
        devTools: {
            actionsDenylist: [
                '@@localize',
                // '@@router',
                '@@redux-form/UNREGISTER_FIELD',
                '@@redux-form/REGISTER_FIELD',
                '@@redux-form/INITIALIZE',
                '@@redux-form/FOCUS',
                '@@redux-form/BLUR',
                '@@redux-form/TOUCH',
                'RNS',
                'bmApi',
                'loading-bar'
            ]
            
        },
        middleware: (getDefaultMiddleware) => [
            ...getDefaultMiddleware({
                // TODO: try enabling this and check /newsletters/send
                immutableCheck: false,
                serializableCheck: false,
            }),
            stripeAuthListener.middleware,
            calculatePriceListener.middleware,
            eaccountingListener.middleware,
            correlationIdListener.middleware,
            formsListener.middleware,
            authListener.middleware,
            resyncCodelockListener.middleware,
            bmApiService.middleware,
            reduxRouterMiddleware,
            sagaMiddleware,
            rtkQueryErrorLogger
        ],
        // @ts-ignore
        enhancers: [responsiveStoreEnhancer],
    });
    sagaMiddleware.run(sagas);

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('./store/reducers', () => {
                store.replaceReducer(rootReducer);
            });
        }
    }

    return store;
};

export const store = createStoreWithInitialState();


export type ApplicationDispatch = typeof store.dispatch;
export const appDispatch: ApplicationDispatch = store.dispatch;

export type ApplicationState = ReturnType<typeof store.getState>;

export type TypedListener = TypedStartListening<ApplicationState, ApplicationDispatch>;

