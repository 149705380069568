// Billing method ids
export const MAIL_INVOICE = 1;
export const EMAIL_INVOICE = 2;
export const CREDIT_CARD = 3;

export const DEFAULT_SCHEDULER_COLOR = '#4fc3f7';

export const BookingStatusIds = {
    BOOKED: 1,
    UNBOOKED: 2,
    RESERVED: 3,
    CANCELED: 4,
    AWAITING_PAYMENT: 5,
    AWAITING_PAYMENT_NO_TIME_LIMIT: 6,
    BOOKED_AND_PAYED: 7,
    AWAITING_PAYMENT_REQUEST: 8,
    AWAITING_MONEY_TRANSFER: 9,
};

export const VAT_VALUES = [0, 0.06, 0.1, 0.12, 0.24, 0.25];

export const TrialTypeIds = {
    SCHEDULER: 1,
    NEWSLETTER: 3,
    BOOKING_REPORT: 4,
};

export const CompanyStatusIds = {
    REGISTERED: 1,
    AWAITING_APPROVAL: 2,
    APPROVED: 3,
    INACTIVE: 4,
    CLOSED_DOWN: 5,
    NOT_APPROVED: 6,
};

export const FolderTypes = {
    LOGOTYPE: 1,
    HOMEPAGE: 2,
    NEWSLETTER: 3,
    RESOURCE: 4,
    SERVICE: 5,
    CUSTOMER_COMMENTS: 6,
    SUPPORT_CASE_ATTACHMENTS: 7,
};


export const INITIAL_ROUTE_KEY = 'initialRoute';

export const AUTH_STORAGE_KEY = 'auth';
export const IS_LOGGED_IN_STORAGE_KEY = 'isLoggedIn';
export const IS_LOADING_STORAGE_KEY = 'isLoading';
export const SHOULD_LOGOUT_STORAGE_KEY = 'shouldLogout';
// TODO stripe: remove feature flag
export const STRIPE_FEATURE_KEY = 'stripeFeature';