import { combineReducers, AnyAction, StateFromReducersMapObject, ReducersMapObject, CombinedState, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { localizeReducer as localize, LocalizeState } from 'react-localize-redux';
import { responsiveStateReducer } from 'redux-responsive';
import { reducer as notifications } from 'react-notification-system-redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import administratorRoles from './administratorRoles';
import administrators from './administrators';
import availableResources from './availableResources';
import availableTimes from './availableTimes';
import billing from './billing';
import billmate from './billmate';
import booking from './newBooking';
import bookingDetails from './bookingDetails';
import bookingLogs from './bookingLogs';
import bookingReportOptions from './bookingReportOptions';
import bookings from './bookings';
import bookingSettings from './bookingSettings';
import bookingStatuses from './bookingStatuses';
import collidingEvents from './collidingEvents';
import company from './company';
import companyCategories from './companyCategories';
import companyTypes from './companyTypes';
import confirm from './confirm';
import coordinates from './coordinates';
import countries from './countries';
import currencies from './currencies';
import customerComments from './customerComments';
import customers from './customers';
import customFields from './customFields';
import customFieldSlots from './customFieldSlots';
import gdpr from './gdpr';
import homepage from './homepage';
import images from './images';
import licenses from './licenses';
import messages from './messages';
import newsletters from './newsletters';
import paymentSettings from './paymentSettings';
import payson from './payson';
import prices from './prices';
import promoCodes from './promoCodes';
import bookingPrintout from './bookingPrintout';
import resources from './resources';
import resourceTypes from './resourcetypes';
import schedules from './schedules';
import schedulePreview from './schedulePreview';
import scheduleTypes from './scheduleTypes';
import serviceGroups from './serviceGroups';
import services from './services';
import settings from './settings';
import statistics from './statistics';
import supportTickets from './supportTickets';
import sync from './sync';
import timeExceptions from './timeExceptions';
import validations from './validations';
import verify from './verify';
import { createHashHistory, History } from 'history';
import codelockSettings from './codelockSettings';
import axemaSettings from './axemaSettings';
import vanderbiltSettings from './vanderbiltSettings';
import parakeySettings from './parakeySettings';
import amidoSettings from './amidoSettings';
import telkeySettings from './telkeySettings';
import siedleSettings from './siedleSettings';
import rcoSettings from './rcoSettings';
import rcoEnablaSettings from './rcoEnablaSettings';
import accessKeyTypes from './accessKeyTypes';
import agreements from './agreements';
import accessySettings from './accessySettings';
import priceViewTypes from './priceViewTypes';
import zesecSettings from './zesecSettings';
import newAuth from './newAuth';

import bmApiService from '@/services/bmApi';
import cancelLicenseQuestionaire from './cancelLicenseQuestionaire';
import resyncCodelock from './resyncCodelock';
import serviceDurationTypes from './servicesDurationTypes';
import calculationTypes from './calculationTypes';
import eaccountingSlice from './eaccounting';
import correlationId from './correlationId';
import calculatePrice from './calculatePrice';
import promoCodesTransactions from './promoCodesTransactions'
import stripeAuth from './stripeAuthSlice';
import mailchimpSettings from './mailchimpSettings';
import webhookSettings from './webhookSettings';
import webhookEndpoints from './webhookEndpoints';

 
const createAppReducer = (history: History) =>
    // @ts-ignore
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        browser: responsiveStateReducer,
        notifications,
        loadingBar: loadingBarReducer,
        localize,
        cancelLicenseQuestionaire: cancelLicenseQuestionaire.reducer,
        administratorRoles,
        administrators,
        authenticate: newAuth.reducer,
        resyncCodelock: resyncCodelock.reducer,
        availableResources,
        availableTimes,
        billing,
        billmate,
        booking,
        bookingDetails,
        bookingLogs,
        bookingReportOptions,
        bookings,
        bookingSettings,
        bookingStatuses,
        collidingEvents,
        company,
        companyCategories,
        companyTypes,
        confirm,
        coordinates,
        countries,
        currencies,
        customerComments,
        customers,
        customFields,
        customFieldSlots,
        gdpr,
        homepage,
        images,
        licenses,
        messages,
        newsletters,
        paymentSettings,
        payson,
        prices,
        promoCodes,
        bookingPrintout,
        resources,
        resourceTypes,
        schedules,
        schedulePreview,
        scheduleTypes,
        serviceGroups,
        services,
        settings,
        statistics,
        supportTickets,
        sync,
        timeExceptions,
        validations,
        verify,
        codelockSettings,
        axemaSettings,
        vanderbiltSettings,
        parakeySettings,
        amidoSettings,
        telkeySettings,
        siedleSettings,
        rcoSettings,
        rcoEnablaSettings,
        accessySettings,
        accessKeyTypes,
        agreements,
        priceViewTypes,
        zesecSettings,
        serviceDurationTypes,
        calculationTypes,
        eaccounting: eaccountingSlice.reducer,
        promoCodesTransactions,
        mailchimpSettings,
        webhookSettings,
        webhookEndpoints,
        [correlationId.name]: correlationId.reducer,
        [calculatePrice.name]: calculatePrice.reducer,
        [stripeAuth.name]: stripeAuth.reducer,
        [bmApiService.reducerPath]: bmApiService.reducer
    });
    

const createRootReducer = (history: History) => (state: any, action: AnyAction) => {
    if (action.type === 'RESET_STORE') {
        const { browser, localize, router } = state;
        state = { browser, localize, router };
    }
    return createAppReducer(history)(state, action);
};

export default createRootReducer;
