import { delay, put, select, take, call} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import qs from 'qs';
import { format } from '@/utils/datetime';
import isEmpty from 'lodash/isEmpty';
import { AnyAction } from 'redux';
import { CREDIT_CARD } from '@/utils/constants';

import { apiClient, throwSubmissionError } from '@/utils';
import * as actions from '@/store/actions';
import downloadPDF from '@/utils/downloadPDF';
import { AxiosResponse } from 'axios';
import { BillingInformation } from '@/types';
import { RootState } from 'MyTypes';
import keycloak from '@/keycloak';

export function* billingSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/billing/company');

        yield put(actions.FETCH_BILLING_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BILLING_SETTINGS.failure(error));
    }
}

export function* editBillingSettings(action: AnyAction) {
    try {
        const initialValues: { [key in string]: string} = yield select((state) => state.billing.settings.data);
        const callApi = isEmpty(initialValues) ? apiClient.post : apiClient.put;
        const response: AxiosResponse = yield callApi(`/billing/company`, action.payload.BillingInformation);

        if(action.payload.BillingInformation.BillingMethodId !== CREDIT_CARD) {
            yield put(actions.EDIT_BILLING_SETTINGS.success(response));
        } else {
            yield put(actions.EDIT_BILLING_SETTINGS.success(response));

            // this is here because we want to make sure the form is saved
            yield take(actions.FETCH_CREDIT_CARD.SUCCESS);
            
            yield put(
                push({
                    pathname: '/billing/add-credit-card',
                    search: qs.stringify(response.data),
                })
            );
        }
    } catch (error) {
        yield put(actions.EDIT_BILLING_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* fetchBillingMethods({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/billing/methods', {
            params: { CountryId: payload || 'SE' },
        });

        yield put(actions.FETCH_BILLING_METHODS.success({ Results: response.data }));
    } catch (error) {
        yield put(actions.FETCH_BILLING_METHODS.failure(error));
    }
}

export function* fetchCompanyCreditCard({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/billing/company/creditcard', {
            params: { IsValid: true },
        });

        yield put(actions.FETCH_CREDIT_CARD.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_CREDIT_CARD.failure(error));
    }
}

export function* fetchBillingToken({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.post('/Token/GenerateRegisterDibsPaymentCardToken');

        yield put(actions.FETCH_BILLING_TOKEN.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_BILLING_TOKEN.failure(error));
    }
}

export function* fetchInvoices({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/billing/company/invoices', {
            params: {
                IncludeCurrencyInformation: true,
                IncludeInvoiceStatusInformation: true,
                ...payload,
            },
        });

        yield put(actions.FETCH_INVOICES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_INVOICES.failure(error));
    }
}

export function* exportInvoice({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/billing/company/invoices/${payload.Id}`, {
            responseType: 'blob',
        });

        yield put(actions.FETCH_EXPORT_INVOICE.success());

        downloadPDF(response.data, `${payload.Id} - ${format(payload.InvoiceDate, 'Pp')}.pdf`);
    } catch (error) {
        yield put(actions.FETCH_EXPORT_INVOICE.failure(error));
    }
}

export function* watchBillingSettings() {
    while (true) {
        yield delay(10000);
        yield call(checkBillingSettings)
    }
}

export function* checkBillingSettings() {
    const billing: RootState['billing'] | undefined = yield select((s) => s?.billing);
    const router: RootState['router'] | undefined = yield select((s) => s?.router);

    if(!billing?.settings.hasError && !billing?.settings.isLoaded && !billing?.settings.isLoading) {
        yield put(actions.FETCH_BILLING_SETTINGS.request());
    }

    if (
        keycloak &&
        keycloak.token && 
        billing?.settings?.hasError &&
        !router?.location.pathname.includes('/billing/settings')
    ) {
        try {
            if (billing?.settings?.hasError) {
                yield put({ type: actions.SHOW_BILLING_INFO_MODAL });
            }

            yield take(actions.EDIT_BILLING_SETTINGS.SUCCESS);
            yield put({ type: actions.HIDE_BILLING_INFO_MODAL });
            yield take(actions.FETCH_COMPANY_LICENSES.SUCCESS);
            // yield put(push('/bookings'));
        } catch (error) {
            // we don't care about the error
        }
    }
}